import React from "react"

import Layout from "../layouts/default"

import TextContainer from "../components/elements/text_container"

export default () => {

  const page = {
    name: 'Error 410',
    slug: '410',
    seo: {
      title: '410',
      robots: 'noindex,follow'
    }
  };
  return (
      <Layout page={page}>
        <TextContainer>
          <h1>:( Diese Seite existiert nicht mehr.</h1>
        </TextContainer>
      </Layout>
  )
};
